var Waypoint = window.Waypoint;
angular.module('sq.directives.scroll', [])
.directive('waypoint', function waypointDirective() {
  return {
    restrict: 'AE',
    scope: {
      offset: '@',
      animationClass: '@',
    },
    // scope: true,
    link: function linkFn($scope, element) {
      element.addClass('transparent');
      var waypoint = new Waypoint({
        element: element[0],
        offset: ($scope.offset || '80%'),
        handler: function handlerFn(/* direction*/) {
          element.addClass('animated ' + ($scope.animationClass || 'fadeInLeft') );
          if (waypoint) { waypoint.destroy(); }
        },
      });
    },
  };
});
